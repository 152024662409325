import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import NewsletterSignup from '../components/newsletterSignup'

class BlogIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulPost.edges')

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: "description", content: "All the information and updates you need to know about insurance for your business and family in Louisiana." }]}  
          title="Insurance Blog - Baton Rouge | Lewis Mohr"
        />
        <div className="site-body">
          <div className="grid grid-gutters grid-justifyCenter">
            <div className="grid-cell">
              <h1 className="posts-page-title">Latest Posts</h1>
              <div className="">
                {posts.map(({ node }) => {
                  const title =
                    get(node, 'title') || node.slug
                  return (
                    <div className="blog-list" key={node.slug}>
                      <Link to={`/posts/${node.slug}/`}>
                        <Img
                          className="mainImagePreview"
                          sizes={
                            node.featuredImage.fluid
                          }
                        />
                      </Link>
                      <div className="blogTitle">          
                        <h1><Link to={`/posts/${node.slug}/`}>{title}</Link></h1>
                        <h6>{node.date}</h6>
                      </div>
                      <p
                        className="blogContentPreview"
                        dangerouslySetInnerHTML={{ __html: node.details.childMarkdownRemark.excerpt }}
                      />
                      <Link className="button" to={`/posts/${node.slug}/`}>
                        Read More
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <NewsletterSignup />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
    mainBg: file(relativePath: { eq: "blog-bg.jpg" }) {
      ...mainBg
    }
    allContentfulPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          slug
          date(formatString: "MMMM D, YYYY")
          featuredImage {
            fluid(maxWidth: 1800, sizes: "") {
              aspectRatio
              sizes
              src
              srcSet
            }
          }
          details {
            childMarkdownRemark {
              excerpt(pruneLength: 150)
            }
          }
        }
      }
    }
  }
`
